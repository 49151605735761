import styled from "styled-components";
import { colors } from "../../constants";

const BaseButton = styled.button`
  background: transparent;
  font: "roboto";
  border-width: 1px;
  margin-top: 2px;
  padding: 2px;
  width: 100%;
  border-color: ${colors.darkgray};
  border-style: solid;
  border-radius: 10px;
  cursor: pointer;
  font-size: normal;
  @media (min-width: 600px) {
    font-size: x-large;
    padding: 10px;
  }
`;

export default BaseButton;
