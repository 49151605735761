import { colors } from "../../constants";
import styled from "styled-components";

export const TitleText = styled.span`
  font-size: normal;
  user-select: none;
  @media (min-width: 600px) {
    font-size: x-large;
  }
`;

function Card(props) {
  const maxWidth = props.wide ? "50vw" : "20vw";

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        backgroundColor: props.disabled ? colors.lightgray : "white",
        borderRadius: 17,

        minWidth: "10em",
        maxWidth: maxWidth,
        borderStyle: "solid",
        borderColor: "#e0e0e0",
        borderWidth: 1,
        fontSize: "24px",
        fontFamily: "roboto",
        padding: "20px",
        margin: 4,
        fontWeight: 500
      }}
    >
      <TitleText>{props.title}</TitleText>
      {!props.center ? (
        <>{props.children}</>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
}

export default Card;
