import { FunctionComponent, useContext } from "react";
import { colors } from "../constants";

import TopBar from "../components/TopBar/TopBar";
import SideBar from "../components/SideBar/SideBar";
import QuizView from "./QuizView";
import { QuizContext } from "../helpers/Contexts";
import CourseView from "../Course";
interface QuizPageProps {}

const QuizPage: FunctionComponent<QuizPageProps> = () => {
  const { menuItem, setMenuItem } = useContext(QuizContext);
  return (
    <>
      <div style={{ flex: 1, display: "flex" }}>
        <SideBar />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <TopBar />
          <div
            style={{
              padding: 6,
              backgroundColor: colors.background,
              display: "grid"
            }}
          >
            {menuItem === "Dashboard" && <QuizView />}
            {menuItem === "Course" && <CourseView />}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizPage;
