import React, { useContext } from "react";
import { QuizContext } from "../../helpers/Contexts";

import "./End.css";
import { COUNTDOWN_DEFAULT_TIME, TOTAL_QUESTION_NUMBER } from "../../constants";
import BaseButton from "../BaseButton/BaseButton";

const End = () => {
  const { gameState, setGameState } = useContext(QuizContext);
  const { score, setScore } = useContext(QuizContext);
  const { counter, setCounter } = useContext(QuizContext);
  const { totalTime, setTotalTime } = useContext(QuizContext);
  const { quizResult, setQuizResult, setCurrentQuestionIndex } =
    useContext(QuizContext);

  const d = new Date();
  var minutes;
  console.log({
    COUNTDOWN_DEFAULT_TIME,
    quizResult,
    totalTime
  });
  var time = COUNTDOWN_DEFAULT_TIME * quizResult.shown - totalTime;
  let result;

  //Result message for timer
  if (time < COUNTDOWN_DEFAULT_TIME * quizResult.shown) {
    result = `your time: ${time} seconds`;
  } else {
    result = "you didn't finish on time.";
  }

  if (d.getMinutes() < 10) {
    minutes = "0" + d.getMinutes();
  } else {
    minutes = d.getMinutes();
  }

  //Try again - show main screen, set score back to 0, set counter back to TIME
  const backOnQuiz = () => {
    setTotalTime(0);
    setScore(0);
    setCurrentQuestionIndex(0);
    setQuizResult({ answered: 0, shown: 0, correct: 0 });
    setCounter(COUNTDOWN_DEFAULT_TIME);
    setGameState("quiz");
  };

  return (
    <div className="End fadeIn delay-0_3">
      <div className="terminal-wrapper">
        <div className="">
          <p className="terminal-prompt terminal-msg">
            Your score: {score}/{TOTAL_QUESTION_NUMBER}{" "}
          </p>
          {/* Timer */}
          <p className="terminal-prompt terminal-msg"> {result} </p>
          {/* Depends on score get different message */}
          <p className="terminal-prompt terminal-msg">
            Head over to the course page, see what you have missed.
          </p>
          {/* End question */}
          <div className="terminal-prompt terminal-text">
            <p className="pl-7">Not satisfied? Try again.</p>
          </div>
          {/* Try again button */}
          <div className="mt-10 terminal-prompt terminal-text terminal-start">
            <BaseButton
              onClick={() => {
                backOnQuiz();
              }}
            >
              Try again
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default End;
