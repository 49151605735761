import React, { FunctionComponent, useContext, useState } from "react";
import Card from "./components/card/Card";
import { colors } from "./constants";
import SubscribeToEmail from "./components/SubscribeToEmail/SubscribeToEmail";
import { QuizContext } from "./helpers/Contexts";
interface CourseViewProps {}

const YoutubeEmbed = () => (
  <div style={{ paddingTop: 20 }}>
    <img width={"100%"} src={"/placeholder.jpeg"} />
    {/* <iframe
      width="800"
      frameBorder="0"
      height="460"
      src={`https://www.youtube.com/embed/0TQnxAyAW8M?si=ouW-mKPyVOglOnik`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    /> */}
  </div>
);

const Dropdown = ({ title, sections, index, isDisabled }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(true);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const preIndex = index + 1;

  return (
    <Card wide title={preIndex + ". " + title} disabled={isDisabled}>
      <div style={{ flex: 1, display: "flex" }} onClick={toggleDropdown}>
        {true && (
          <div>
            {/* <Icon
              style={{ padding: 10 }}
              path={icon}
              size={"100px"}
              color={colors.text}
            /> */}
            {sections.map((section: string, index) => (
              <p style={{ fontSize: 16 }} key={index}>
                {"- " + section}
              </p>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};

const CourseView: FunctionComponent<CourseViewProps> = () => {
  const { questions, setQuestions } = useContext(QuizContext);
  return (
    <div>
      <Card wide title={"2024 Hero Assistant - HomeAssistant course"}>
        {YoutubeEmbed()}
      </Card>
      <Card wide>
        <SubscribeToEmail />
      </Card>

      {questions.map((chapter, index) => (
        <Dropdown
          isDisabled={chapter.correct}
          index={index}
          title={chapter.course.title}
          key={index}
          sections={chapter.course.sections}
        />
      ))}
      <Card wide>
        <SubscribeToEmail />
      </Card>
    </div>
  );
};

export default CourseView;
