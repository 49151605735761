import React, { createContext, useState } from "react";
import { Question, Questions } from "./Questions";
import { COUNTDOWN_DEFAULT_TIME } from "../constants";
import useStateWithLocalStorage from "./useStateWithLocalStorage";

type StateContextType = {
  gameState: string;
  setGameState: React.Dispatch<React.SetStateAction<string>>;
  score: number;
  setScore: React.Dispatch<React.SetStateAction<number>>;
  questions: Question[];
  setQuestions: React.Dispatch<React.SetStateAction<Question[]>>;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  totalTime: number;
  setTotalTime: React.Dispatch<React.SetStateAction<number>>;
  quizResult: { shown: number; answered: number; correct: number };
  setQuizResult: React.Dispatch<
    React.SetStateAction<{ shown: number; answered: number; correct: number }>
  >;
  currentQuestion: Question;
  setCurrentQuestion: React.Dispatch<React.SetStateAction<Question>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  menuItem: string;
  setMenuItem: React.Dispatch<React.SetStateAction<string>>;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  currentQuestionIndex;
};

export function ContextProvider({ children }) {
  // State changes here only affect context consumers
  const [counter, setCounter] = useState(COUNTDOWN_DEFAULT_TIME);
  const [questions, setQuestions] = useStateWithLocalStorage(
    "questions",
    Questions
  );
  const [totalTime, setTotalTime] = useState(0);
  const [gameState, setGameState] = useStateWithLocalStorage(
    "gameState",
    "quiz"
  );
  const [menuItem, setMenuItem] = useStateWithLocalStorage(
    "menuItem",
    "Dashboard"
  );
  const [isOpen, setIsOpen] = useStateWithLocalStorage("isOpen", false);
  const [score, setScore] = useStateWithLocalStorage("score", 0);
  const [quizResult, setQuizResult] = useStateWithLocalStorage("quizStats", {
    shown: 0,
    answered: 0,
    correct: 0
  });
  const [currentQuestion, setCurrentQuestion] = useStateWithLocalStorage(
    "currentQuestion",
    undefined
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] =
    useStateWithLocalStorage("currentQuestionIndex", 0);

  return (
    <QuizContext.Provider
      value={{
        setCurrentQuestionIndex,
        currentQuestionIndex,
        gameState,
        setGameState,
        score,
        setScore,
        questions,
        setQuestions,
        counter,
        setCounter,
        setTotalTime,
        totalTime,
        setQuizResult,
        quizResult,
        currentQuestion,
        setCurrentQuestion,
        isOpen,
        setIsOpen,
        menuItem,
        setMenuItem
      }}
    >
      {children}
    </QuizContext.Provider>
  );
}
export const QuizContext = createContext<StateContextType>(
  null as unknown as StateContextType
);
