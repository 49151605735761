import React, { useState, useContext, useEffect, useRef } from "react";
import { QuizContext } from "../../helpers/Contexts";
import "./Quiz.css";
import {
  COUNTDOWN_DEFAULT_TIME,
  IS_DEV,
  POST_SUBMIT_DELAY,
  TOTAL_QUESTION_NUMBER
} from "../../constants";
import BaseButton from "../BaseButton/BaseButton";
import { Question, Questions } from "../../helpers/Questions";
import LightButton from "../LightButton/LightButton";

const Quiz = () => {
  const {
    setCurrentQuestion,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    setGameState,
    questions,
    setQuestions,
    counter,
    setCounter
  } = useContext(QuizContext);

  const { totalTime, setTotalTime } = useContext(QuizContext);
  const { score, setScore } = useContext(QuizContext);
  const { quizResult, setQuizResult } = useContext(QuizContext);
  const [isRight, setIsRight] = useState<number>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [optionChosen, setOptionChosen] = useState<number | null>();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const isMobile = () => windowSize.current[0] < 800;
  let btns = document.getElementsByClassName("optionBtn");
  const d = new Date();
  let minutes;
  let lastQuestion;

  if (d.getMinutes() < TOTAL_QUESTION_NUMBER) {
    minutes = "0" + d.getMinutes();
  } else {
    minutes = d.getMinutes();
  }

  useEffect(() => {
    if (!questions) {
      setQuestions(Questions);
    }
  }, []);

  useEffect(() => {
    //timer for quiz - when timer reach 0 end screen appear
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setGameState("end");
    }
    //add glowing effect to circle depend on counter
    if (counter > 100 && counter <= 240) {
      //   document.getElementById("green").classList.add("green-pulse");
    } else if (counter > 50 && counter <= 100) {
      // document.getElementById("yellow").classList.add("yellow-pulse");
      //   document.getElementById("green").classList.remove("green-pulse");
    } else if (counter >= 0 && counter <= 50) {
      // document.getElementById("yellow").classList.remove("yellow-pulse");
      // document.getElementById("red").classList.add("red-pulse");
    }
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (optionChosen === null) {
      document.getElementById("nextBtn").setAttribute("disabled", "disabled");
    } else {
      document.getElementById("nextBtn").removeAttribute("disabled");
    }
  }, [optionChosen]);

  useEffect(() => {
    setQuizResult({ ...quizResult, shown: quizResult.shown + 1 });
  }, []);

  const incrementState = () => {
    const notLastQuestion = quizResult.shown !== TOTAL_QUESTION_NUMBER;

    // if right answer
    if (questions[currentQuestionIndex].answer === optionChosen) {
      setQuizResult({
        ...quizResult,
        correct: quizResult.correct + 1,
        answered: quizResult.answered + 1,
        ...(notLastQuestion && { shown: quizResult.shown + 1 })
      });
    } else {
      setQuizResult({
        ...quizResult,
        answered: quizResult.answered + 1,
        ...(notLastQuestion && { shown: quizResult.shown + 1 })
      });
    }
  };

  //functionality for the next question
  const nextQuestion = () => {
    setIsSubmitted(true);
    setIsRight(questions[currentQuestionIndex].answer);
    // setQuizResult({ ...quizResult, shown: quizResult.shown + 1 });
    setCounter(COUNTDOWN_DEFAULT_TIME);
    setTotalTime(totalTime + counter);
    // setQuestionCounter(questionCounter + 1);

    //if user answered correctly increase score so we can keep track how many correct answers user had
    //change colors of the answers - green if it is correct, red if it is wrong
    incrementState();

    const isCorrect = questions[currentQuestionIndex].answer === optionChosen;
    if (isCorrect) {
      setScore(score + 1);
      // document.getElementById(
      //   "btn-" + questions[currentQuestionIndex].answer
      // ).style.color = colors.green;
    } else {
      // document.getElementById("btn-" + optionChosen).style.color = colors.red;
    }

    setQuestions([
      // Items before the insertion point:
      ...questions.slice(0, currentQuestionIndex),
      // New item:
      { ...questions[currentQuestionIndex], correct: isCorrect },
      // Items after the insertion point:
      ...questions.slice(currentQuestionIndex + 1, questions.length)
    ]);

    //if questionCounter is less then 10 - show next question + increase questionCounter
    if (quizResult.shown < TOTAL_QUESTION_NUMBER) {
      setTimeout(function () {
        setCurrentQuestion(questions[currentQuestionIndex]);
        //disable next question btn
        setIsSubmitted(false);
        setOptionChosen(null);
        setIsRight(null);
        setCurrentQuestionIndex(currentQuestionIndex + 1);

        //remove background image
        document.getElementById("app").style.backgroundImage = "url('')";

        //remove styles from buttons
        for (const element of btns) {
          // @ts-ignore
          element.style.color = "#E6C027";
          // @ts-ignore
          element.style.background = "transparent";
          // @ts-ignore
          element.style.borderColor = "#E6C027";
        }
      }, POST_SUBMIT_DELAY);
      //else if it is bigger - show end screen
    } else {
      setTimeout(function () {
        setIsSubmitted(false);
        setIsRight(null);
        //disable next question btn
        setOptionChosen(null);
        //when user answer on question number 10 we render end component
        if (quizResult.shown === TOTAL_QUESTION_NUMBER) {
          setGameState("end");
        }
      }, POST_SUBMIT_DELAY);
    }
  };

  //restart quiz - go back to main screen, set score to 0 and counter to 240 seconds
  const restartQuiz = () => {
    setScore(0);
    setQuizResult({ shown: 0, answered: 0, correct: 0 });
    setCounter(COUNTDOWN_DEFAULT_TIME);
    setGameState("main");
  };

  //Show Yes/No and hide giveUp btn when we click on giveUp btn
  const areYouSure = () => {
    var modal = document.getElementsByClassName("terminal-giveUp");
    document.getElementById("giveUp-section").style.display = "none";
    for (const element of modal) {
      // @ts-ignore
      element.style.display = "flex";
    }
  };

  //Hide Yes/No and show giveUp btn when we click on No btn
  const cancel = () => {
    let modal = document.getElementsByClassName("terminal-giveUp");
    document.getElementById("giveUp-section").style.display = "flex";
    for (const element of modal) {
      // @ts-ignore
      element.style.display = "none";
    }
  };

  //if we reached last question change path for next question from /Question_? to /End
  if (quizResult.shown < TOTAL_QUESTION_NUMBER) {
    lastQuestion = `Question_${quizResult.shown + 1}`;
  } else {
    lastQuestion = "End";
  }

  const renderQuestions = (question: Question) => {
    return question.options.map((option: string, index: number) => {
      const isActive = index === optionChosen;
      const onClick = () => {
        isActive ? setOptionChosen(null) : setOptionChosen(index);
      };

      const isWrong = isSubmitted && isActive && index !== isRight;

      return (
        <LightButton
          isRight={isRight === index}
          isWrong={isWrong}
          onClick={onClick}
          isActive={isActive}
          isSubmitted={isSubmitted}
          option={option}
          key={index}
          isCorrect={index === 0}
          index={index}
        />
      );
    });
  };

  console.log(currentQuestionIndex, counter, score, quizResult);

  return (
    <div className="Quiz fadeIn delay-0_3">
      {IS_DEV && (
        <button
          onClick={() => {
            localStorage.clear();
          }}
        >
          clear local storage
        </button>
      )}
      <div style={{ marginTop: 10 }}>
        <div>
          <span style={{ fontSize: isMobile() ? "small" : "normal" }}>
            {questions && questions[currentQuestionIndex].question}
          </span>
          <div
            style={{
              marginTop: 10,
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(2, 1fr)",
              gridColumnGap: 10,
              gridRowGap: 10
            }}
          >
            {questions && renderQuestions(questions[currentQuestionIndex])}
          </div>
          <div className="mt-10 terminal-prompt terminal-text terminal-start">
            {/* Change next button text when we reach last question */}
            <BaseButton
              disabled={isSubmitted}
              id="nextBtn"
              onClick={() => {
                nextQuestion();
              }}
            >
              {"Next"}
            </BaseButton>
          </div>
          {/* Give up button */}
          {/* <div
            id="giveUp-section"
            className="mt-10 terminal-prompt terminal-text terminal-start fadeIn"
          >
            <BaseButton
              id="giveUpBtn"
              onClick={() => {
                areYouSure();
              }}
            >
              Give up
            </BaseButton>
          </div> */}
          <div className="fadeIn delay-0_3 mt-10 terminal-prompt terminal-text terminal-start terminal-giveUp">
            <p className="terminal-prompt terminal-msg">Are you sure?</p>
          </div>
          <div className="fadeIn delay-0_5 mt-10 terminal-prompt terminal-text terminal-start terminal-giveUp">
            <BaseButton
              onClick={() => {
                restartQuiz();
              }}
              className="giveUpBtn giveUp-transition"
            >
              Yes
            </BaseButton>
          </div>
          <div className="fadeIn delay-0_7 mt-10 terminal-prompt terminal-text terminal-start terminal-giveUp">
            <BaseButton
              onClick={() => {
                cancel();
              }}
            >
              No
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
