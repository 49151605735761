export const Questions: Question[] = [
  {
    question: "What is the primary configuration file for Home Assistant?",
    difficulty: 0,
    options: [
      "configuration.yaml",
      "settings.conf",
      "home_config.ini",
      "hass_settings.yaml"
    ],
    answer: 0,
    course: {
      title: "Introduction to Home Automation and Home Assistant",
      sections: [
        "Overview of Smart Homes",
        "Importance of Automation in Daily Life",
        "Introduction to Home Assistant",
        "Installing Home Assistant OS",
        "Installing Home Assistant as a Docker container"
      ]
    }
  },
  {
    question:
      "Which protocol is commonly used for communication between Home Assistant and smart devices?",
    difficulty: 0,
    options: ["MQTT", "HTTP", "FTP", "UDP"],
    answer: 0,
    course: {
      title: "Home Assistant Fundamentals",
      sections: [
        "Adding Smart Devices to Home Assistant",
        "Integrating Popular Smart Home Brands",
        "Troubleshooting Common Device Setup Issues",
        "Creating Basic Automations",
        "Customizing Scripts for Specific Scenarios",
        "Advanced Automation Techniques"
      ]
    }
  },
  {
    question: "What is Dashboards in Home Assistant?",
    difficulty: 1,
    options: [
      "Voice Assistant",
      "User authentication system",
      "Automation scripting language",
      "Customizable frontend interface"
    ],
    answer: 3,
    course: {
      title: "Customization and Theming",
      sections: [
        "Themes and Styling Options",
        "UI Customization for Better User Experience",
        "Integrating Floor Plans and Custom Backgrounds",
        "Overview of Dashboard UI",
        "Building Custom Dashboards",
        "Utilizing Dashboard Cards for Different Devices"
      ]
    }
  },
  {
    question:
      "How can you group devices together in Home Assistant for easier control?",
    difficulty: 1,
    options: [
      "Using YAML only",
      "Through device pairing",
      "Creating device folders",
      "Utilizing Groups or Areas"
    ],
    answer: 3,
    course: {
      title: "Customization and Theming",
      sections: [
        "Themes and Styling Options",
        "UI Customization for Better User Experience",
        "Integrating Floor Plans and Custom Backgrounds",
        "Overview of Lovelace UI",
        "Building Custom Dashboards",
        "Utilizing Lovelace Cards for Different Devices"
      ]
    }
  },
  {
    question:
      "Which YAML key is used to define the automation trigger in Home Assistant?",
    difficulty: 2,
    options: ["`trigger`", "`event`", "`condition`", "`automation`"],
    answer: 0,
    course: {
      title: "Introduction to Third-Party Plugins",
      sections: [
        "Overview of Home Assistant Add-ons",
        "Installing and Configuring Add-ons",
        "Popular Third-Party Integrations",
        "Installing and Setting Up HACS",
        "Exploring and Installing Community-Created Components",
        "Managing and Updating Third-Party Plugins"
      ]
    }
  },
  {
    question: "What is the purpose of the Home Assistant Add-ons?",
    difficulty: 2,
    options: [
      "Decorative elements for the interface",
      "Additional features and services",
      "User permissions management",
      "Custom device integrations"
    ],
    answer: 1,
    course: {
      title: "Advanced Automation Techniques",
      sections: [
        "Using Node-RED with Home Assistant",
        "Integrating Node-RED for Visual Automation",
        "Creating Complex Automations with Node-RED",
        "Introduction to AppDaemon",
        "Writing Automation Scripts with Python",
        "Introduction to ESPhome"
      ]
    }
  },
  {
    question:
      "What is the purpose of the Home Assistant configuration check tool?",
    difficulty: 0,
    options: [
      "Configuring device preferences",
      "Validating the configuration for errors",
      "Adjusting network settings",
      "Installing new integrations"
    ],
    answer: 1,
    course: {
      title: "Troubleshooting and Optimization",
      sections: [
        "Troubleshooting Connectivity Problems",
        "Handling Configuration Errors",
        "Debugging Automations",
        "Managing Resources on Different Platforms",
        "Performance Tips and Best Practices",
        "Monitoring and Logging"
      ]
    }
  },
  {
    question:
      "Which of the following is a valid platform for Home Assistant automations?",
    difficulty: 0,
    options: [
      "`motion_sensor`",
      "`coffee_maker`",
      "`rainbow_lights`",
      "`weather_forecast`"
    ],
    answer: 3,
    course: {
      title: "Security and Best Practices",
      sections: [
        "Implementing Secure Access Methods",
        "User Management and Permissions",
        "Security Best Practices for Home Automation",
        "Setting Up Automated Backups",
        "Restoring Home Assistant from Backups",
        "Disaster Recovery Strategies"
      ]
    }
  },
  {
    question: "How can you customize the appearance of cards in Dashboards UI?",
    difficulty: 1,
    options: [
      "Using HTML code",
      "CSS styles in the configuration.yaml file",
      "Themes only",
      "Through the UI editor"
    ],
    answer: 3,
    course: {
      title: "Real-Life Applications",
      sections: [
        "Exploring Real-World Applications of Home Assistant",
        "Case Studies of Successful Home Automation Setups",
        "Learning from Community Experiences"
      ]
    }
  },
  {
    question:
      "In Home Assistant, what is the purpose of the `secrets.yaml` file?",
    difficulty: 1,
    options: [
      "Storing device names",
      "Managing user access permissions",
      "Safely storing sensitive information like passwords",
      "Creating custom automations"
    ],
    answer: 2,
    course: {
      title: "Conclusion and Next Steps",
      sections: [
        "Recap and Summary",
        "Reviewing Key Concepts and Skills Learned",
        "Discussing the Potential of Home Assistant in Future Projects",
        "Resources for Continued Learning",
        "Community Forums and Support",
        "Keeping Up-to-Date with Home Assistant Developments"
      ]
    }
  }

  // {
  //   question:
  //     "When defining a condition in Home Assistant automation, what does the `all` keyword do?",
  //   difficulty: 2,
  //   options: [
  //     "Requires all specified conditions to be true",
  //     "Applies OR logic to the conditions",
  //     "Activates the automation when any condition is true",
  //     "Excludes the specified conditions from the automation"
  //   ],
  //   answer: 0
  // },
  // {
  //   question: "How can you create a custom Lovelace card in Home Assistant?",
  //   difficulty: 2,
  //   options: [
  //     "Utilizing the `custom_card.yaml` file",
  //     "Adding a JavaScript file to the www folder",
  //     "Only through official Home Assistant releases",
  //     "Modifying the core Lovelace files directly"
  //   ],
  //   answer: 1
  // },
  // {
  //   question:
  //     "What is the purpose of the `!secret` tag in YAML files in Home Assistant?",
  //   difficulty: 3,
  //   options: [
  //     "Excluding a configuration entry from version control",
  //     "Denying access to specific users",
  //     "Encrypting sensitive information within the file",
  //     "Forcing an immediate automation trigger"
  //   ],
  //   answer: 2
  // },
  // {
  //   question:
  //     "In Home Assistant, what is the primary function of the `input_boolean` entity?",
  //   difficulty: 3,
  //   options: [
  //     "Controlling boolean logic in automations",
  //     "Displaying boolean status on the frontend",
  //     "Integrating boolean devices into the system",
  //     "Enabling boolean-based security features"
  //   ],
  //   answer: 0
  // },
  // {
  //   question:
  //     "How can you update Home Assistant to the latest version using the command line?",
  //   difficulty: 3,
  //   options: [
  //     "`ha update`",
  //     "`pip install homeassistant --upgrade`",
  //     "`apt-get upgrade home-assistant`",
  //     "Home Assistant updates are automatic and do not require manual intervention."
  //   ],
  //   answer: 1
  // },
  // {
  //   question:
  //     "What is the purpose of the `customize.yaml` file in Home Assistant?",
  //   difficulty: 1,
  //   options: [
  //     "Configuring custom device classes",
  //     "Setting up user permissions",
  //     "Defining custom automations",
  //     "Managing frontend interface layouts"
  //   ],
  //   answer: 0
  // },
  // {
  //   question:
  //     "How can you create a time-based trigger for an automation in Home Assistant?",
  //   difficulty: 2,
  //   options: [
  //     "`at: '12:00'`",
  //     "`trigger: time: '12:00'`",
  //     "`condition: time: '12:00'`",
  //     "`platform: time, at: '12:00'`"
  //   ],
  //   answer: 3
  // },
  // {
  //   question:
  //     "In Home Assistant, what is the purpose of the `script` component?",
  //   difficulty: 3,
  //   options: [
  //     "Defining custom JavaScript functions",
  //     "Creating interactive frontend scripts",
  //     "Specifying custom device configurations",
  //     "Managing sequences of actions to be executed"
  //   ],
  //   answer: 3
  // },
  // {
  //   question:
  //     "What is the purpose of the `configuration.yaml` key `allowlist_external_dirs`?",
  //   difficulty: 2,
  //   options: [
  //     "Enabling external access to Home Assistant directories",
  //     "Specifying directories accessible by external integrations",
  //     "Defining a list of allowed external devices",
  //     "Configuring external media storage"
  //   ],
  //   answer: 1
  // },
  // {
  //   question:
  //     "How can you check the configuration of Home Assistant for errors without restarting the server?",
  //   difficulty: 1,
  //   options: [
  //     "`ha check`",
  //     "`ha config check`",
  //     "`ha validate`",
  //     "`ha configuration validate`"
  //   ],
  //   answer: 0
  // },
  // {
  //   question:
  //     "In Home Assistant, what is the purpose of the `device_tracker` platform?",
  //   difficulty: 0,
  //   options: [
  //     "Tracking the physical location of devices",
  //     "Managing device-specific configurations",
  //     "Controlling device access permissions",
  //     "Monitoring device performance metrics"
  //   ],
  //   answer: 0
  // }
];

export interface Question {
  question: string;
  options: string[];
  answer: number;
  difficulty: number;
  course: {
    title: string;
    sections: string[];
  };
  correct?: boolean;
}

export enum Answer {
  A = "a",
  B = "b",
  C = "c",
  D = "d"
}
