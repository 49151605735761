import { FunctionComponent, useContext, useState } from "react";
import Icon from "@mdi/react";
import { mdiMenuOpen, mdiMenu, mdiViewDashboard, mdiBook } from "@mdi/js";
import { colors } from "../../constants";
import styled from "styled-components";
import { Avatar } from "@mui/material";
import { QuizContext } from "../../helpers/Contexts";

interface SideBarProps {}

const menuItems = [
  { title: "Dashboard", icon: mdiViewDashboard },
  { title: "Course", icon: mdiBook }
];

const MenuText = styled.span<{ $isSelected: boolean }>`
  margin-left: 10px;
  font-weight: 500;
  user-select: none;
  color: ${(props) => (props.$isSelected ? colors.homeassistant : colors.text)};
`;

const iconPaddingLeft = 14;

const MenuItem = styled.div<{ $mouseDown: boolean; $menuItem: boolean }>`
  padding-left: ${iconPaddingLeft - 2}px;
  cursor: pointer;
  padding-top: 10px;
  margin-top: 4px;
  padding-bottom: 10px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 4px;
  ${(props) => props.$mouseDown && "background: #e4e4e4"};
  ${(props) => props.$menuItem && `background-color: ${colors.selected}`};
`;

const SideBar: FunctionComponent<SideBarProps> = () => {
  const { isOpen, setIsOpen } = useContext(QuizContext);
  const { menuItem, setMenuItem } = useContext(QuizContext);
  const [mouseDown, setOnMouseDown] = useState<string>();
  const iconSize = 1.2;
  const width = isOpen ? 300 : 60;
  return (
    <div
      style={{
        width: width,
        minHeight: "100%",
        border: 1,
        borderStyle: "solid",
        borderBottom: 0,
        borderColor: "#e0e0e0"
      }}
    >
      <div
        style={{
          paddingLeft: iconPaddingLeft,
          flex: 1,
          display: "flex",
          alignItems: "center",
          height: 54,
          borderBottom: "1px solid #e0e0e0",
          cursor: "pointer"
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <Icon
            path={isOpen ? mdiMenuOpen : mdiMenu}
            size={iconSize}
            color={colors.text}
          />
        </div>
        {isOpen && (
          <span
            style={{
              marginLeft: 20,
              lineHeight: "20px",
              fontSize: 20,
              fontWeight: 500,
              color: colors.text,
              userSelect: "none"
            }}
          >
            Home assistant
          </span>
        )}
      </div>
      <ul
        style={{
          listStyleType: "none",
          padding: 0
        }}
      >
        {menuItems.map((item) => {
          const isSelected = menuItem === item.title;
          return (
            <li key={item.title}>
              <MenuItem
                $mouseDown={mouseDown === item.title}
                $menuItem={isSelected}
                onMouseDown={() => setOnMouseDown(item.title)}
                onMouseUp={() => setOnMouseDown("")}
                onClick={() => setMenuItem(item.title)}
              >
                <Icon
                  path={item.icon}
                  size={iconSize}
                  color={isSelected ? colors.homeassistant : colors.text}
                />

                {isOpen && (
                  <MenuText $isSelected={isSelected}>{item.title}</MenuText>
                )}
              </MenuItem>
            </li>
          );
        })}
      </ul>
      <div
        style={{
          display: "flex",
          position: "fixed",
          bottom: 0,
          minWidth: width,
          flexDirection: "column",
          borderTop: "1px solid #e4e4e4"
        }}
      >
        <MenuItem
          $menuItem={menuItem === "Avatar"}
          onClick={() => setMenuItem("Avatar")}
          $mouseDown={false}
          style={{
            marginBottom: "auto",
            padding: 0,
            paddingLeft: 6,
            paddingTop: 6,
            paddingBottom: 6
          }}
        >
          <Avatar src="/avatar-snow.jpg" />
          {isOpen && (
            <MenuText
              $isSelected={menuItem === "Avatar"}
              style={{ marginLeft: 6 }}
            >
              Your teacher
            </MenuText>
          )}
        </MenuItem>
      </div>
    </div>
  );
};

export default SideBar;
