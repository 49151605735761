import { mdiEmail } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useContext, useRef, useState } from "react";
import { TOTAL_QUESTION_NUMBER, colors } from "../../constants";
import axios from "axios";
import { QuizContext } from "../../helpers/Contexts";
import getBrowserFingerprint from "get-browser-fingerprint";
const fingerprint = getBrowserFingerprint();

interface SubscribeToEmailProps {}

const SubscribeToEmail: FunctionComponent<SubscribeToEmailProps> = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const { questions, quizResult } = useContext(QuizContext);
  const handleChange = (value: string) => {
    setEmail(value.replaceAll(" ", ""));
  };

  const [showThanks, setShowThanks] = useState(false);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    const error =
      !/^[A-Za-z0-9._%+-]{1,64}@(?:[A-Za-z0-9-]{1,63}\.){1,125}[A-Za-z]{2,63}$/.test(
        email
      );

    if (error) {
      setError(error);
    } else {
      const res = await axios.post("https://quizb.keyb.io", {
        email: email,
        results: { ...questions, quizResult: quizResult },
        maximum: TOTAL_QUESTION_NUMBER,
        correct: quizResult.correct,
        signature: fingerprint.toString()
      });
      setShowThanks(true);
      setTimeout(() => {
        setEmail("");
        setShowThanks(false);
      }, 2000);
    }
  };

  const isMobile = () => windowSize.current[0] < 800;
  const inputStyle = {
    padding: 10,
    borderRadius: 4,
    marginLeft: 0,
    borderWidth: 1,
    width: "96%",
    maxWidth: 300,
    fontSize: "16px"
  };
  return (
    <div>
      <section>
        {isMobile() && (
          <center>
            <Icon path={mdiEmail} size={"100px"} color={colors.text} />
          </center>
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          {!isMobile() && (
            <Icon
              style={{ padding: 10 }}
              path={mdiEmail}
              size={"100px"}
              color={colors.text}
            />
          )}
          <div>
            <p>Get the course 30% discounted!</p>
            <span style={{ fontSize: "16px" }}>
              I hate spam, promise to only contact you about the release of the
              course with the discount code.
            </span>
            <div>
              {showThanks ? (
                <p>Thank you {email}!</p>
              ) : (
                <form onSubmit={handleSubmit}>
                  <input type="submit" hidden />
                  <input
                    type="text"
                    id="email"
                    style={
                      error
                        ? { border: "1px solid red", ...inputStyle }
                        : inputStyle
                    }
                    placeholder="Your email"
                    value={email}
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                  />
                  <button
                    style={{
                      backgroundColor: colors.homeassistant,
                      border: "none",
                      borderRadius: 5,
                      color: "white",
                      padding: "12px 32px",
                      textAlign: "center",
                      textDecoration: "none",
                      display: "inline-block",
                      fontSize: "16px",
                      cursor: "pointer"
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubscribeToEmail;
