import { ContextProvider } from "./helpers/Contexts";
import "@fontsource/roboto";
import QuizPage from "./QuizPage/QuizPage";
import LoadingPage from "./LoadingPage/LoadingPage";
import { useState } from "react";

const App = () => {
  const [loaded, setLoaded] = useState(false);
  setTimeout(() => {
    setLoaded(true);
  }, 2000);

  return (
    <div className="App" id="app">
      <ContextProvider>
        {loaded === false ? <LoadingPage /> : <QuizPage />}
      </ContextProvider>
    </div>
  );
};

export default App;
