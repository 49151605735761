import { FunctionComponent } from "react";
import LoadingSpin from "react-loading-spin";
import { colors } from "../constants";

interface LoadingPageProps {}

const LoadingPage: FunctionComponent<LoadingPageProps> = () => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column"
      }}
    >
      {/* <img
        style={{ width: 200, height: 200, marginBottom: 20 }}
        src={"/ha_loading.png"}
      /> */}
      <LoadingSpin
        primaryColor={colors.homeassistant}
        secondaryColor={"#fff"}
        size={"40px"}
      />
      <span style={{ marginTop: 20 }}>Loading data</span>
    </div>
  );
};

export default LoadingPage;
