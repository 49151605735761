import { FunctionComponent, useContext } from "react";
import calculateSteps from "../helpers/calculateSteps";
import Card from "../components/card/Card";
import { colors } from "../constants";
import { QuizContext } from "../helpers/Contexts";
import GaugeComponent from "react-gauge-component";

interface DifficultyGaugeProps {}
const getDifficulty = (num: number) => {
  if (num === 0) return "Easy";
  if (num === 1) return "Normal";
  if (num === 2) return "Hard";
  if (num === 3) return "Very Hard";
};

const DifficultyGauge: FunctionComponent<DifficultyGaugeProps> = (props) => {
  const { currentQuestion } = useContext(QuizContext);
  return (
    <Card center title={"Difficulty"}>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <GaugeComponent
          type="semicircle"
          arc={{
            width: 0.4,
            padding: 0,
            cornerRadius: 1,
            subArcs: [
              {
                length: 25,
                color: colors.limegreen,
                tooltip: {
                  text: "Easy"
                }
              },
              {
                length: 25,
                color: colors.khaki,

                tooltip: {
                  text: "Normal"
                }
              },
              {
                length: 25,
                color: colors.crimson,
                tooltip: {
                  text: "Hard"
                }
              },
              {
                length: 25,
                color: colors.darkred,
                tooltip: {
                  text: "Very hard"
                }
              }
            ]
          }}
          pointer={{
            color: "#345243",
            length: 0.8,
            width: 15,
            elastic: true
          }}
          labels={{
            valueLabel: { formatTextValue: (value) => undefined },
            tickLabels: {
              defaultTickLineConfig: { hide: true },
              hideMinMax: true
            }
          }}
          value={
            currentQuestion?.difficulty
              ? calculateSteps(currentQuestion.difficulty)
              : 0
          }
          minValue={0}
          maxValue={100}
        />

        <center>
          <span>{getDifficulty(currentQuestion?.difficulty)}</span>
        </center>
      </div>
    </Card>
  );
};

export default DifficultyGauge;
