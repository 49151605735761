import { FunctionComponent, useContext } from "react";
import { QuizContext } from "../helpers/Contexts";
import Card from "../components/card/Card";
import ProgressBar from "@ramonak/react-progress-bar";
import { calculateProgress } from "../helpers/calculateProgress";
import {
  COUNTDOWN_DEFAULT_TIME,
  IS_DEV,
  TOTAL_QUESTION_NUMBER,
  colors
} from "../constants";
import Quiz from "../components/quiz/Quiz";
import End from "../components/end/End";
import DifficultyGauge from "./DifficultyGauge";

interface QuizViewProps {}

const QuizView: FunctionComponent<QuizViewProps> = () => {
  const { quizResult, setQuizResult } = useContext(QuizContext);
  const { counter, setCounter } = useContext(QuizContext);
  const { gameState, setGameState } = useContext(QuizContext);
  const { score, setScore } = useContext(QuizContext);
  return (
    <>
      <Card wide title={"Quiz progress"}>
        <div style={{ marginTop: 30 }}>
          <ProgressBar
            isLabelVisible={false}
            completed={calculateProgress(
              quizResult.answered,
              TOTAL_QUESTION_NUMBER
            )}
            bgColor={colors.homeassistant}
          />
        </div>
      </Card>
      <Card wide high title={"Quiz question"}>
        {gameState === "quiz" && <Quiz />}
        {gameState === "end" && <End />}
      </Card>
      <div style={{ display: "flex", flex: 1 }}>
        {/* <Card center title={"Timer"}>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <GaugeChart
              nrOfLevels={3}
              percent={counter / COUNTDOWN_DEFAULT_TIME}
              style={{ width: "100%" }}
              animate={false}
              arcPadding={0}
              arcWidth={0.4}
              hideText={true}
              cornerRadius={0}
              colors={[colors.crimson, colors.khaki, colors.lime]}
            />

            <center>
              <span>{counter} seconds</span>
            </center>
          </div>
        </Card> */}
        <DifficultyGauge />
        {IS_DEV && (
          <Card center title={"Quiz score"}>
            <span
              className="terminal-prompt last-login"
              style={{ fontSize: "xx-large" }}
            >
              {score}/{TOTAL_QUESTION_NUMBER} points
              <p>correct: {quizResult.correct}</p>
              <p>answered: {quizResult.answered}</p>
              <p>shown: {quizResult.shown}</p>
            </span>
          </Card>
        )}
      </div>
    </>
  );
};

export default QuizView;
