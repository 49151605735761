import { FunctionComponent } from "react";
import { colors } from "../../constants";

interface TopBarProps {}

const TopBar: FunctionComponent<TopBarProps> = () => {
  return (
    <div
      style={{
        height: 56,
        width: "100%",
        display: "flex",
        backgroundColor: colors.homeassistant,
        alignItems: "center"
      }}
    >
      <p
        style={{
          marginLeft: 24,
          lineHeight: "20px",
          flexGrow: 1,
          fontSize: 20,
          color: "white",
          userSelect: "none"
        }}
      >
        Home Assistant
      </p>
    </div>
  );
};

export default TopBar;
