import styled, { css, keyframes } from "styled-components";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { FunctionComponent } from "react";
import { ANIMATION_DURATION_SECONDS, colors } from "../../constants";
import { CodeBlock, monoBlue } from "react-code-blocks";

const ButtonDiv = styled.div<{}>`
  aspect-ratio: 1 / 1;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.darkgray};
  border-radius: 17px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: medium;
  @media (min-width: 600px) {
    font-size: x-large;
  }
`;

interface LightButtonProps {
  onClick: any;
  index: number;
  isSubmitted: boolean;
  isActive: boolean;
  option: string;
  isCorrect: boolean;
  isWrong: boolean;
  isRight: boolean;
}

const toSelectedKey = keyframes`
 100% { color: ${colors.gold}; }
`;

const toUnselectedKey = keyframes`
  100% { color: ${colors.steelblue}; }
`;

const toValidKey = keyframes`
 100% { color: ${colors.lightgreen}; }
`;

const toInvalidKey = keyframes`
 100% { color: ${colors.crimson}; }
`;

const toUnselected = (props) => css<{}>`
  ${toUnselectedKey} ${ANIMATION_DURATION_SECONDS}s forwards;
`;

const toInvalid = (props) => css<{}>`
  ${toInvalidKey} ${ANIMATION_DURATION_SECONDS}s forwards;
`;

const toValid = (props) => css<{}>`
  ${toValidKey} ${ANIMATION_DURATION_SECONDS}s forwards;
`;

const toSelected = (props) => css<{}>`
  ${toSelectedKey} ${ANIMATION_DURATION_SECONDS}s forwards;
`;

const getRightAnimation = ({
  $isActive,
  $isCorrect,
  $isSubmitted,
  $isWrong,
  $isRight
}) => {
  if ($isSubmitted) {
    if ($isRight) {
      return toValid;
    }
    if ($isWrong) {
      return toInvalid;
    }
  }

  if ($isActive) {
    return toSelected;
  } else {
    return toUnselected;
  }
};

const BulbIcon = styled(LightbulbIcon)<{
  $isActive: boolean;
  $isCorrect: boolean;
  $isSubmitted: boolean;
  $isWrong: boolean;
  $isRight: boolean;
}>`
  animation: ${(props) => getRightAnimation(props)};
  color: ${colors.steelblue};
`;

const renderButton = (option: string) => {
  if (option.startsWith("`")) {
    return (
      <CodeBlock
        text={option.replaceAll("`", "")}
        showLineNumbers={false}
        language="en"
        wrapLongLines
        theme={monoBlue}
        customStyle={{
          width: "100%",
          borderRadius: "5px",
          fontSize: "1rem",
          margin: "0px 0px",
          paddingTop: "0px"
        }}
      />
    );
  } else {
    return <span style={{ fontSize: "0.8em" }}>{option}</span>;
  }
};

const LightButton: FunctionComponent<LightButtonProps> = ({
  onClick,
  option,
  isWrong,
  isRight,
  isSubmitted,
  index,
  isActive,
  isCorrect
}) => {
  const size = "10vw";

  return (
    <ButtonDiv onClick={onClick}>
      <div style={{ marginTop: "auto", marginBottom: 20 }}></div>
      <BulbIcon
        $isWrong={isWrong}
        $isRight={isRight}
        $isActive={isActive}
        $isCorrect={isCorrect}
        $isSubmitted={isSubmitted}
        style={{ height: size, width: size }}
        id={`btn-${index}`}
      />
      <div
        style={{
          marginTop: "auto",
          marginBottom: 10
        }}
      >
        {renderButton(option)}
      </div>
    </ButtonDiv>
  );
};

export default LightButton;
